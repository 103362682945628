
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import { GelirGiderEntity } from "@/entity/GelirGiderEntity";
import GelirGiderTurPicker from "@/components/pickers/GelirGiderTurPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Kur from "@/components/inputs/Kur.vue";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: { Dates, Kur, ParaBirimiPicker, Tutar, GelirGiderTurPicker, FormWrapper }
})
export default class GelirGiderForm extends Mixins(ObjectInputMixin) {
  @Prop({ default: null }) gelirMi!: boolean | null;

  localValue!: GelirGiderEntity;

  get isGelir(): boolean {
    return this.gelirMi !== null ? this.gelirMi : this.localValue.gelir_mi;
  }

  @Watch('gelirMi', { immediate: true })
  onGelirMiChange(newValue: boolean | null) {
    if (newValue !== null) {
      this.localValue.gelir_mi = newValue;
    }
  }

  save() {
    if (this.localValue.id) {
      this.$http.put('/api/v1/kasa-hareketi/' + this.localValue.id, this.localValue).then(() => (this.$emit('onSuccess')));
    } else {
      const newData = { ...this.localValue };
      if (this.gelirMi !== null) {
        newData.gelir_mi = this.gelirMi;
      }
      newData.hesaba_eklensin_mi = true;
      newData.kasa_id = 1;
      this.$http.post('/api/v1/kasa-hareketi', newData).then(() => (this.$emit('onSuccess')));
    }
  }
}
